<template>
  <div>
    <v-hover v-slot="{ hover }" close-delay="100">
      <div>
        <div>
          <span class="cursor-pointer" v-show="!hideNumber">
            <v-btn v-if="icon" icon color="primary">
              <v-icon>
                {{ icon }}
              </v-icon>
            </v-btn>
            {{ number }}
          </span>
          <v-expand-transition>
            <div v-if="hover || alwaysShow">
              <div class="d-flex justify-center align-center mt-2">
                <slot name="prepend"></slot>
                <slot name="message" v-if="number && type === 'mobile'">
                  <a
                    :href="`sms:${number}`"
                    class="decoration-none mr-5"
                    v-show="call"
                    target="_blank"
                  >
                    <div class="d-flex align-center justify-center flex-column rounded-lg">
                      <v-btn icon color="primary" class="v-btn--active" v-bind="$attrs">
                        <v-icon> mdi-message </v-icon>
                      </v-btn>
                      <div class="mt-1">Message</div>
                    </div>
                  </a>
                </slot>
                <slot name="call" v-if="number">
                  <a :href="`tel:${number}`" class="decoration-none" v-show="call" target="_blank">
                    <div class="d-flex align-center justify-center flex-column rounded-lg">
                      <v-btn icon color="primary" class="v-btn--active" v-bind="$attrs">
                        <v-icon> mdi-phone </v-icon>
                      </v-btn>
                      <div class="mt-1">Call</div>
                    </div>
                  </a>
                </slot>
                <slot name="append"></slot>
              </div>
            </div>
          </v-expand-transition>
        </div>
      </div>
    </v-hover>
  </div>
</template>

<script>
import Contact from '../services/contact';
export default {
  props: {
    hideNumber: {
      type: Boolean,
      default: false
    },
    alwaysShow: {
      type: Boolean,
      default: false
    },
    call: {
      type: Boolean,
      default: true
    },
    message: {
      type: Boolean,
      default: true
    },
    number: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  computed: {
    icon() {
      return Contact.phoneIcon(this.type);
    }
  }
};
</script>

<style>
</style>