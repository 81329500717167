import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VHover,{attrs:{"close-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[_c('div',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideNumber),expression:"!hideNumber"}],staticClass:"cursor-pointer"},[(_vm.icon)?_c(VBtn,{attrs:{"icon":"","color":"primary"}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.number)+" ")],1),_c(VExpandTransition,[(hover || _vm.alwaysShow)?_c('div',[_c('div',{staticClass:"d-flex justify-center align-center mt-2"},[_vm._t("prepend"),(_vm.number && _vm.type === 'mobile')?_vm._t("message",[_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.call),expression:"call"}],staticClass:"decoration-none mr-5",attrs:{"href":("sms:" + _vm.number),"target":"_blank"}},[_c('div',{staticClass:"d-flex align-center justify-center flex-column rounded-lg"},[_c(VBtn,_vm._b({staticClass:"v-btn--active",attrs:{"icon":"","color":"primary"}},'v-btn',_vm.$attrs,false),[_c(VIcon,[_vm._v(" mdi-message ")])],1),_c('div',{staticClass:"mt-1"},[_vm._v("Message")])],1)])]):_vm._e(),(_vm.number)?_vm._t("call",[_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.call),expression:"call"}],staticClass:"decoration-none",attrs:{"href":("tel:" + _vm.number),"target":"_blank"}},[_c('div',{staticClass:"d-flex align-center justify-center flex-column rounded-lg"},[_c(VBtn,_vm._b({staticClass:"v-btn--active",attrs:{"icon":"","color":"primary"}},'v-btn',_vm.$attrs,false),[_c(VIcon,[_vm._v(" mdi-phone ")])],1),_c('div',{staticClass:"mt-1"},[_vm._v("Call")])],1)])]):_vm._e(),_vm._t("append")],2)]):_vm._e()])],1)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }