import { getFirestore } from './firebase'
import { randomNumber } from '../utils'
import Phone from './phone'
export default class Contact {
    constructor() {
        this.firestore = getFirestore()
        this.data = null;
        this.ref = this.firestore.collection('contactDetail')
        this.fields =
            [
                'photo',
                'name',
                'firstName',
                'lastName',
                'email',
                'address',
                'phone',
                'company',
                'tags',
                'bio',
                'note'
            ];
    }
    setData(data) {
        this.data = { ...(data || {}) };
        return this
    }
    isFieldEmpty = (field) => {
        if (!this.data || typeof this.data !== 'object') {
            return true
        }

        const data = this.data[field]

        if (['name', 'firstName', 'lastName'].includes(field)) {
            return !(this.data.name || this.data.firstName || this.data.lastName)
        }
        if (field === 'phone') {
            return !(Array.isArray(data) && data.length > 0 && data.some(i => i?.number))
        } else if (field === 'address') {
            return !(Array.isArray(data) && data.length > 0 && data.some(i => i?.street || i?.state || i?.city || i?.zip))
        }
        else if (field === 'tags') {
            return !(Array.isArray(data) && data.length > 0 && data.some(i => i))
        }
        return !this.data[field];
    }
    isEmpty() {
        let res = true;
        this.fields.forEach(key => {
            if (!this.isFieldEmpty(key)) {
                res = false
            }
        });
        return res
    }
    static phoneIcon(label) {
        const data = {
            mobile: 'mdi-cellphone',
            home: 'mdi-home',
            work: 'mdi-office-building',
        }
        return data[label] || 'mdi-phone'
    }
    static validateData(data, formatPhone = true) {
        const defaultPhone = [{ label: 'mobile', number: '', id: randomNumber() }]
        const defaultAddress = [{ street: '', city: '', zip: '', state: '', label: 'home', id: randomNumber(), gps: '' }]

        let res = {
            phone: defaultPhone,
            address: defaultAddress,
            firstName: '',
            lastName: '',
            email: '',
            street: '',
            city: '',
            state: '',
            zip: '',
            company: '',
            tags: [],
            imageUrl: '',
            imageId: ''
        }

        res = { ...res, ...data }
        if (typeof res.phone === 'string') {
            res.phone = [{ label: 'mobile', number: res.phone }]
        }
        if (typeof res.tags === 'string') {
            res.tags = [res.tags]
        }

        if (!res.address || !Array.isArray(res.address)) {
            res.address = defaultAddress
        }
        if (!res.phone || !Array.isArray(res.phone)) {
            res.phone = defaultPhone
        }

        if (formatPhone) {
            res.phone.forEach((phone, index) => {
                const p = new Phone({ phone: phone.number })
                res.phone[index].number = p.format()
            })
        }

        const address = res.address[0]
        if (!!!(address.street || address.city || address.zip || address.state)) {
            res.address[0] = {
                street: res.street || '',
                city: res.city || '',
                state: res.state || '',
                zip: res.zip || '',
                label: 'home',
                id: randomNumber()
            }
        }

        //check if phone has unique id assigned
        res.phone.forEach((phone, index) => {
            if (!phone.id) {
                res.phone[index].id = randomNumber()
            }
        });

        //check if address has unique id assigned
        res.address.forEach((address, index) => {
            if (!address.id) {
                res.address[index].id = randomNumber()
            }
        });

        if (!res.firstName && !res.lastName && res.name) {
            const [firstName, lastName] = String(res.name || '').split(" ")
            res.firstName = firstName
            res.lastName = lastName
        }

        if (!res.address[0].gps && res?.gps) {
            res.address[0].gps = res?.gps || ''
        }

        return res
    }
    get(id) {
        return new Promise((resolve, reject) => {
            this.ref.doc(id).get().then(res => {
                if (res.exists) {
                    const data = { ...res.data() }
                    const name = data.name || ''
                    if (name && !(data.firstName || data.lastName)) {
                        const [first, last] = name.split(" ")
                        data.firstName = first
                        data.lastName = last
                    }
                    resolve({ id, data })
                }
                else {
                    reject(new Error("contact does not exists"))
                }
            }).catch(err => reject(err))
        })
    }
    create(data) {
        return this.ref.add({ ...data, createdAt: new Date() })
    }
    update(data, id) {
        const dataValidated = { ...data, updatedAt: new Date() }
        if (!dataValidated.createdAt) {
            dataValidated.createdAt = new Date()
        }
        if (dataValidated.phone && Array.isArray(dataValidated.phone)) {
            dataValidated.phone = dataValidated.phone.map((phone) => {
                const p = new Phone({ phone: phone.number });
                return { ...phone, number: p.phone }
            })
        }
        return this.ref.doc(id).set(dataValidated)
    }
}